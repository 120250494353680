import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import { Helmet } from "react-helmet"

import HeroSection from "../components/home-page/heroSection"
import TrustedBySection from "../components/home-page/trustedBySection"
import PricingSection from "../components/home-page/pricingSection"
import PricingSection2 from "../components/home-page/pricingSection2"
import ShowcaseSection from "../components/home-page/showcaseSection"
import ContactSection from "../components/home-page/contactSection"
import ArchiveSection from "../components/home-page/archiveSection"
import Footer from "../components/footer"

import '../scss/style.scss'

import CheckoutHTML from "../components/checkoutHTML"


const IndexPage = () => (
  <Layout >
    <SEO title="Home" />
    <Helmet>
      <meta property="og:url" content="https://www.mailsculpt.com/" />
      <meta property="og:image" content="https://www.mailsculpt.com/static/27072ea9a6944a3209d1672361feea68/630fb/MailSculptLogo.png" />
    </Helmet>


    <HeroSection />

    <TrustedBySection />


    <PricingSection />
    {/* <PricingSection2 /> */}


    {/* <ShowcaseSection /> */}


    <ContactSection />


    <ArchiveSection />



    <Footer />





  </Layout >
)

export default IndexPage
