import React from "react"

import HeroImg from "../images/heroImg"
import CallsLogo from "../images/callSharkLogo"
import heroGif from "../../images/SmallScreensGif.gif"
import HeroVid from "../../images/HeroVideo.mp4"
import HeroVid2 from "../../images/HeroVideo.webm"
import HeroVidImage from "../../images/HeroVideoImage.jpg"
import heroComputers from "../../images/HeroScreensGIF_10thJune.gif"
import HotelHomeLogo from "../../images/HotelHomeLogo.png"
import CallSharkLogo from "../../images/CallSharkLogo.png"
import DailyIdeaLogo from "../../images/DailyIdeaLogo.png"
import HarmanbroLogo from "../../images/HarmanbroLogo.png"
import NoyLogo from "../../images/NoyLogo.png"
import RedSmokeAlarmLogo from "../../images/RedSmokeAlarmLogo.png"



import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import '../../scss/style.scss'

import styled from "styled-components"





const TrustedBySection = () => (




    <div style={{ backgroundColor: "#F7FAFE", height: "100%", width: "100%", }}>

        <Container>

            <Row className="justify-content-lg-center">
                <Col style={{ maxWidth: "900px" }}>
                    <p className="h1 heroh1 text-center" style={{ marginTop: "60px", color: "rgba(0,0,0,0.87)", fontWeight: "normal", fontFamily: "Roboto" }}><strong>Smart</strong> People use Mail Sculpt.</p>

                    <Row className="justify-content-lg-center" style={{ marginTop: "30px", marginBottom: "40px" }} >
                        <Col xs={6} md={3}>
                            <img src={CallSharkLogo} style={{ filter: " grayscale(60%)", marginTop: "50px" }} />
                            {/* <CallsLogo imgStyle={{ filter: "greyscale(60%)", marginTop: "50px" }} /> */}

                        </Col>
                        <Col xs={6} md={3}><img src={DailyIdeaLogo} style={{ filter: "grayscale(60%)", paddingTop: "14px", marginTop: "50px" }} /></Col>
                        <Col xs={6} md={3}><img src={HarmanbroLogo} style={{ filter: "grayscale(60%)", paddingTop: "18px", marginTop: "50px" }} /></Col>
                        {/* <Col xs={3}><img src={CallSharkLogo} style={{ filter: "grayscale(60%)", paddingTop: "10px" }} /></Col> */}
                        <Col xs={6} md={3}><img src={HotelHomeLogo} style={{ filter: "grayscale(60%)", marginTop: "50px" }} /></Col>
                    </Row>


                </Col>
            </Row>

            {/* <hr style={{ maxWidth: "800px", marginRight: "auto", marginLeft: "auto" }} /> */}



            {/* <Row className="justify-content-lg-center">

                <Col>
                    <div style={{ maxWidth: "800px", marginRight: "auto", marginLeft: "auto" }}>
                        <h3>Another satisfied customer</h3>
                        <p style={{ fontFamily: "Roboto", fontSize: "22px", fontStyle: "Italic" }}>As a software engineer, it's challenging to get email templates right. You read horror stories of people who try to build their own only to have it break in half the email clients or, worse, get blocked by spam filters. So, when I needed a new email template for Callshark, I knew to find an expert. It helps to have a deep understanding of the "quirks" in each mail client and the know-how to make sure emails always look great. I researched several vendors, and Mail Sculpt's designs looked the best, and their pricing was also the most transparent.</p>

                        <p style={{ fontFamily: "Roboto", fontSize: "22px", fontStyle: "Italic" }}>I sent a request for a quote and got a proposal back within a few hours. I was impressed, so we started the project right away! Tommy sent a detailed timeline of what to expect and when we would receive our initial designs. Mail Sculpt beat that initial deadline, and the quality of the work was terrific right out of the gate. They built us a "framework" of various elements (headlines, paragraphs, video placeholders, buttons, etc.), so we can customize our email template for new campaigns in the future, too. They documented and tested our template across all the major email clients — including screenshots of exactly how it'll look. In short, I would not hesitate to work with the Mail Sculpt — we got a top-notch email template at an extremely affordable price. </p>

                        <p style={{ fontFamily: "Roboto", fontSize: "22px", fontWeight: "bold", fontStyle: "Italic" }}>— Tyler Scott, Founder &amp; CEO Callshark</p>


                    </div>
                </Col>
            </Row> */}


        </Container>

    </div >



)

export default TrustedBySection